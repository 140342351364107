<template>
  <div class="standart-events">
    <div v-if="isMobileScreen && !$lodash.isEmpty(this.eventAllSponsors)" class="sponsors-section">
      <div
        v-for="({ details, id }) in eventAllSponsors"
        :key="id"
        class="sponsor-div"
      >
        <img :src="details.logo" >
        <p class="sponsor-name">{{ details.name }}</p>
      </div>
    </div>

    <b-row class="event-day-year">
      <b-col cols="1" class="pl-0">
        <p v-if="!isMobileScreen" class="single-event-global-date m-0">
             {{ $formatDate.getUTCDate(event.start_date) }}-{{
            $formatDate.getUTCDate(event.end_date)
          }}
          {{ $formatDate.getUTCMonth(event.start_date) }}
          {{ $formatDate.getUTCYear(event.start_date) }}
        </p>
        <router-link v-else :to="{ name: 'ccAllEvents' }" exact>
          <i class="fas fa-arrow-left" />
        </router-link>
      </b-col>
      <b-col>
        <p class="event-text m-0">{{ event.name }}</p>
      </b-col>
    </b-row>
    <div class="event-img">
      <img :src="event.banner" alt />
    </div>
    <div class="event-details">
      <vue-countdown-timer
        :start-time="event.start_date"
        :end-time="`${event.end_date} 20:00`"
        :interval="1000"
        :end-label="'Until end:'"
        label-position="begin"
        :end-text="'Event ended!'"
        :day-txt="'days'"
        :hour-txt="'hours'"
        :minutes-txt="'minutes'"
        :seconds-txt="'seconds'"
      >
        <template slot="countdown" slot-scope="scope">
          will take place in
          <span style="text-transform: uppercase">
            {{ scope.props.days }} {{ scope.props.dayTxt }} •
            {{ scope.props.hours }} {{ scope.props.hourTxt }} •
            {{ scope.props.minutes }} {{ scope.props.minutesTxt }} •
            {{ scope.props.seconds }} {{ scope.props.secondsTxt }}</span
          >
        </template>
        <template slot="end-label" slot-scope="scope">
          <span
            v-if="
              scope.props.startLabel !== '' &&
              scope.props.tips &&
              scope.props.labelPosition === 'end'
            "
            >{{ scope.props.startLabel }}:</span
          >
          <span
            v-if="
              scope.props.endLabel !== '' &&
              !scope.props.tips &&
              scope.props.labelPosition === 'end'
            "
            >{{ scope.props.endLabel }}:</span
          >
        </template>

        <template slot="end-text" slot-scope="scope">
          <span class="ended-event">{{ scope.props.endText }}</span>
        </template>
      </vue-countdown-timer>
    </div>
    <div id="right-event-buttons" v-if="isEndedEvent()">
      <button
        class="bookThisEvent bookArea"
        @click="goToEventBooking"
        v-if="!isBooked && showBookButton"
      >
        <i class="fa fa-check"></i> Book this event
      </button>
      <button v-else-if="isUnAvailable()" class="mpButtonNotActive bookArea" disabled>
        <i class="fa fa-calendar"></i> Online Meeting Planner is not available
      </button>
      <router-link
        v-else-if="isMpActive() && isBooked"
        :to="{
          name: 'meetingPlanner',
          params: { eventUrl: event.url, eventId: event.id },
        }"
        class="bookArea"
      >
        <button class="bookThisEvent">
          <i class="fa fa-calendar"></i> Online Meeting Planner available
        </button>
      </router-link>
      <button class="mpButtonNotActive bookArea" disabled v-else-if="isBooked && !isMpActive()">
        <i class="fa fa-calendar"></i>
        Meeting Planner will be open at {{ mpSubtractDays() }}
      </button>
      <br v-if="!isMobileScreen" />
      <router-link
        :to="{
          name: 'BecomeSponsorEvent',
          params: { eventId: $route.params.eventId },
        }"
        class="becomeASponsor"
      >
        <button class="becomeASponsor-btn">Become a Sponsor</button>
      </router-link>
      <br v-if="!isMobileScreen" />
      <router-link
        :to="{
          name: 'BecomePannelist',
          params: { eventId: $route.params.eventId },
        }"
        class="panelistArea"
      >
        <button class="panelist">Become a Panelist</button>
      </router-link>
    </div>
    <div class="events-buttons">
      <b-row id="all-event-btns">
        <router-link
          v-for="name in components"
          :key="name"
          class="standart-event-button-name"
          :to="{ name: name, params: { event } }"
          >{{ name }}</router-link
        >
      </b-row>
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { getOwnerId } from '../../utils/getOwnerId';


export default {
  name: 'StandardEvents',
  data() {
    return {
      components: [
        'Info',
        'Map',
        'Agenda',
        'Venue',
        'Accommodation',
        'Speakers',
        'Exhibitors',
        'Booked Tables',
        'Meeting Rooms',
        'Media',
        'Companies',
      ],
      component: 'Info',
      isPostActive: true,
      showBookButton: false,
      eventFinancialEntity: null,
      eventIsNotEnded: false,
      isEntityMatch: false,
      invoices: [],
    };
  },
  computed: {
    ...mapGetters({
      event: 'events/fetchEvent',
      userInfo: 'user/fetchUserInfo',
      participantMembers: 'events/participantMembers',
      isMobileScreen: 'isMobileScreen',
      eventAllSponsors: 'events/eventAllSponsors',
    }),
    isBooked() {
      const { participant_customers, url } = this.event;
      let index;
      if (participant_customers) {
        index = participant_customers.findIndex(
          participant => participant.customer_id === this.userInfo.id,
        );
        if (index !== -1) return true;
      }
      index = this.invoices.findIndex(
        invoice => invoice.event === url || invoice.award === url,
      );
      return index !== -1;
    },
  },
  watch: {
    '$route.params.eventId': {
      async handler(eventId) {
        await this.fetchParticipantMembers({
          eventId,
          searchName: '',
        });
      },
    },
  },
  async mounted() {
    const { eventId } = this.$route.params;
    try {
      if (eventId) {
        await this.fetchEvent(eventId).then(
          ({ end_date, financial_entity }) => {
            this.eventFinancialEntity = financial_entity;
            this.eventIsNotEnded = end_date >= this.moment().format('YYYY-MM-DD');
          },
        );
        if (this.isMobileScreen) await this.fetchEventAllSponsors(eventId);
        await this.fetchParticipantMembers({
          eventId,
          searchName: '',
        });
        await this.fetchParticipantCompanies({
          eventId,
          searchName: '',
        });
        await this.fetchEventPrices(eventId);
        await this.fetchPaymentEntities().then(({ results }) => {
          results.forEach((entity) => {
            if (
              this.eventFinancialEntity === entity.url
              && entity.bank_accounts.length
            ) this.isEntityMatch = true;
          });
        });
        if (this.isEntityMatch && this.eventIsNotEnded) {
          this.showBookButton = true;
        }
        await this.fetchUserInvoices(getOwnerId()).then(
          ({ results }) => (this.invoices = results),
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    ...mapActions({
      fetchEvent: 'events/fetchEvent',
      fetchParticipantCompanies: 'events/fetchParticipantCompanies',
      fetchParticipantMembers: 'events/fetchParticipantMembers',
      fetchEventPrices: 'events/fetchEventPrices',
      fetchPaymentEntities: 'companies/fetchPaymentEntities',
      fetchUserInvoices: 'user/fetchUserInvoices',
      fetchEventAllSponsors: 'events/fetchEventAllSponsors',
    }),
    moment(date) {
      return date ? moment(new Date(date).toUTCString()) : moment(new Date().toUTCString());
    },
    isEndedEvent() {
      return this.event.end_date >= this.moment().format('YYYY-MM-DD');
    },
    goToEventBooking() {
      this.$router.push({
        name: 'BookEventDetails',
        params: { eventId: this.event.id },
      });
    },
    eventDays(start, end) {
      let day = this.moment(start).format('DD');
      if (start !== end) {
        day = `${this.moment(start).format('DD')} - ${this.moment(end).format('DD')}`;
      }
      return day;
    },
    mpSubtractDays() {
      const fullDate = this.event.meeting_planner_activation_date
        ? this.moment(this.event.meeting_planner_activation_date)
        : this.moment(this.event.start_date).subtract(21, 'days');
      return `${fullDate.format('DD')} ${fullDate.format('MMM')}`;
    },
    isMpActive() {
      return this.event.meeting_planner_is_active
        ? this.moment(this.moment().format('YYYY-MM-DD')).isSameOrAfter(
          this.moment(this.event.meeting_planner_activation_date).format(
            'YYYY/MM/DD',
          ),
        )
        : this.moment(
          this.moment().add(21, 'days').format('YYYY-MM-DD'),
        ).isSameOrAfter(moment(this.event.start_date).format('YYYY/MM/DD'));
    },
    isUnAvailable() {
      let unAvailable = false;
      this.event.participant_customers.forEach((customer) => {
        if (customer.customer_id === getOwnerId() && !customer.display) unAvailable = true;
      });
      if (this.isMpActive() && !this.event.display_participants) {
        unAvailable = true;
      }
      if (!this.event.meeting_planner_is_active) unAvailable = true;
      return unAvailable;
    },
  },
};
</script>

<style scoped>
.standart-events {
  position: relative;
}
#all-event-btns {
  margin: 0 0 5px 0;
  overflow: auto;
  display: grid;
  grid-auto-flow: column;
  padding-bottom: 0px;
  overflow-y: hidden;
  justify-content: start;
}

.standart-event-button-name {
  font-size: 10px;
  background-color: #ffffff;
  color: #bd9d5f;
  border-radius: 0 10px 10px 10px;
  margin-right: 4px;
  text-decoration: none;
  padding: 4px 8px;
  width: max-content;
  line-height: 1.17;
}
.router-link-exact-active,
.router-link-active {
  color: #fff !important;
  background-color: #bd9d5f;
}
.panelistArea, .becomeASponsor{
  background-color: transparent;
}
.countDown-text {
  direction: rtl;
}
.event-details {
  margin-top: 1px;
  background-color: #333333;
  color: white;
  font-family: Arial;
  font-size: 10px;
  padding: 2px 5px;
  text-align: right;
  border-radius: 0 0 10px 10px;
}
.event-day-year {
  background: white;
  height: 61px;
  margin: 0;
  border-radius: 0 10px 0 0;
}
.single-event-global-date {
  width: 61px;
  font-size: 14px;
  color: #464646;
  line-height: 16px;
  padding-top: 6px;
  font-weight: 400;
  text-align: center;
}
.event-text {
  font-size: 22px;
  color: rgb(42, 42, 42);
  font-family: Montserrat;
  line-height: 60px;
  text-align: center;
}
.event-img {
  height: 240px;
  background-color: #c0c0c0;
  display: flex;
}
.event-img > img {
  width: 100%;
  height: 242px;
}
button {
  margin-right: 3px;
}
.events-buttons {
  width: 100%;
  margin:10px 0;
  padding-top: 10px;
}

#right-event-buttons {
  position: absolute;
  right: 8px;
  top: 5.4rem !important;
}
.panelist {
  margin-top: 15px;
  background: #ffffff;
  border: unset;
  color: #bd9d5f;
  width: 142px;
  text-align: center;
  padding: 10px 10px 10px 8px;
  border-radius: 0 10px 10px 10px;
  text-align: center;
  font-size: 14px;
  margin-left: 12px;
  outline: none;
  box-shadow: unset;
}
.bookThisEvent {
  background: #bd9d5f;
  border-radius: 0 10px 10px 10px;
  border: unset;
  color: white;
  width: 142px;
  text-align: center;
  padding: 10px 10px 10px 8px;
  font-size: 14px;
  outline: none;
  margin-left: 12px;
  box-shadow: unset;
}
.mpButtonNotActive {
  background-color: #fff;
  border: unset;
  padding: 5px;
  width: 142px;
  font-size: 11px;
  text-align: center;
  color: #4e4e4e;
  margin-left: 12px;
}
.mpButtonNotActive:focus {
  outline: none;
}
.becomeASponsor-btn {
  margin-top: 15px;
  background: #ffffff;
  border: unset;
  color: #bd9d5f;
  width: 142px;
  text-align: center;
  padding: 10px 10px 10px 8px;
  text-align: center;
  font-size: 14px;
  outline: none;
  margin-left: 12px;
  border-radius: 0px 10px 10px 10px;
  box-shadow: unset;
}
.members-btn {
  margin-top: 15px;
  background: #ebebeb;
  border: unset;
  color: #333333;
  width: 142px;
  text-align: center;
  padding: 10px 10px 10px 8px;
  text-align: center;
  font-size: 14px;
  margin-left: 12px;
  outline: none;
  box-shadow: unset;
}
.company-btn {
  margin-top: 15px;
  background: #ebebeb;
  border: unset;
  color: #333333;
  width: 142px;
  text-align: center;
  padding: 10px 10px 10px 8px;
  text-align: center;
  font-size: 14px;
  outline: none;
  margin-left: 12px;
  box-shadow: unset;
}
.glob-btn {
  margin-top: 15px;
  background: #ebebeb;
  border: unset;
  color: #333333;
  width: 142px;
  text-align: center;
  padding: 10px 10px 10px 8px;
  text-align: center;
  font-size: 14px;
  box-shadow: unset;
  outline: none;
  margin-left: 12px;
}
.toggle-btn:hover {
  opacity: 0.5;
  transition: 0.4;
}
.toggle-btn {
  color: white;
  background: #bd9d5f;
  padding: 3px 7px 3px 9px;
  font-size: 10px;
  border: none;
  border-radius: unset;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  outline: none;
}

.card-header {
  padding: 0 !important;
}
.btn-info {
  color: #ffffff;
  background-color: #3c3c3c;
  border: unset;
  font-size: 12px;
  outline: unset;
}
.btn-info:hover {
  opacity: 0.3;
  background-color: #3c3c3c;
  outline: unset;
  outline: 0 !important;
  box-shadow: unset !important;
}
.btn-info:focus {
  outline: 0 !important;
  border: unset;
  box-shadow: unset;
}
.toggle-btn:focus {
  background-color: #bd9d5f;
  opacity: unset !important;
}
.ended-event {
  margin-left: 80px;
}
@media (max-width: 768px) {
  .event-day-year {
    padding: 12px;
    height: 47px;
    border-radius: 0 10px 0 0;
  }
  .event-day-year>div>a {
    background-color: #f3f3f3;
    padding: 5px;
    border-radius: 50%;
    color: #333;
  }
  .event-text {
    font-size: 13px;
    color: #333;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    .standart-event-button-name.router-link-exact-active,
    .standart-event-button-name.router-link-active {
      color: #bd9d5f !important;
      background-color: #fff !important;
    }
  }
  .standart-event-button-name {
    color: #7f7f7f;
    background-color: #f3f3f3;
  }
  .event-details {
    background-color: #ddd;
    color: #666;
    font-size: 8px;
    padding: 2px 5px 1px;
    line-height: 1;
  }
  .bookArea { grid-area: book; }
  .sponsorArea { grid-area: sponsor; }
  .panelistArea { grid-area: panelist; }
  #right-event-buttons {
    position: initial;
    width: 100%;
    margin-top: 2px;
    display: grid;
    grid:
      'panelist sponsor'
      'book book';
    grid-gap: 2px;
  }
  .bookThisEvent,
  .mpButtonNotActive {
    background-color: #fff;
    color: #bd9d5f;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 8px;
    font-size: 11px;
    margin: 0;
  }
  .becomeASponsor-btn,
  .panelist {
    width: 100%;
    padding: 8px;
    font-size: 11px;
    margin: 0;
  }
  .events-buttons {
    margin-top: 2px;
  }
  .sponsors-section {
    padding-bottom: 10px;
    overflow: auto;
    white-space: nowrap;
  }
  .sponsor-div {
    width: 90px;
    height: 80px;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    background-color: #fff;
  }
  .sponsor-div:last-child {
    margin-right: 0;
  }
  .sponsor-div > img {
    height: 100%;
    width: 100%;
  }
  .sponsor-name {
    position: absolute;
    top: 3px;
    right: 4px;
    font-size: 5px;
    color: #aaa;
    height: 14px;
    overflow: hidden;
    word-break: break-word;
    width: 72px;
    text-align: right;
  }
}
</style>
