var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"standart-events"},[(_vm.isMobileScreen && !_vm.$lodash.isEmpty(this.eventAllSponsors))?_c('div',{staticClass:"sponsors-section"},_vm._l((_vm.eventAllSponsors),function(ref){
var details = ref.details;
var id = ref.id;
return _c('div',{key:id,staticClass:"sponsor-div"},[_c('img',{attrs:{"src":details.logo}}),_c('p',{staticClass:"sponsor-name"},[_vm._v(_vm._s(details.name))])])}),0):_vm._e(),_c('b-row',{staticClass:"event-day-year"},[_c('b-col',{staticClass:"pl-0",attrs:{"cols":"1"}},[(!_vm.isMobileScreen)?_c('p',{staticClass:"single-event-global-date m-0"},[_vm._v("\n           "+_vm._s(_vm.$formatDate.getUTCDate(_vm.event.start_date))+"-"+_vm._s(_vm.$formatDate.getUTCDate(_vm.event.end_date))+"\n        "+_vm._s(_vm.$formatDate.getUTCMonth(_vm.event.start_date))+"\n        "+_vm._s(_vm.$formatDate.getUTCYear(_vm.event.start_date))+"\n      ")]):_c('router-link',{attrs:{"to":{ name: 'ccAllEvents' },"exact":""}},[_c('i',{staticClass:"fas fa-arrow-left"})])],1),_c('b-col',[_c('p',{staticClass:"event-text m-0"},[_vm._v(_vm._s(_vm.event.name))])])],1),_c('div',{staticClass:"event-img"},[_c('img',{attrs:{"src":_vm.event.banner,"alt":""}})]),_c('div',{staticClass:"event-details"},[_c('vue-countdown-timer',{attrs:{"start-time":_vm.event.start_date,"end-time":((_vm.event.end_date) + " 20:00"),"interval":1000,"end-label":'Until end:',"label-position":"begin","end-text":'Event ended!',"day-txt":'days',"hour-txt":'hours',"minutes-txt":'minutes',"seconds-txt":'seconds'},scopedSlots:_vm._u([{key:"countdown",fn:function(scope){return [_vm._v("\n        will take place in\n        "),_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n          "+_vm._s(scope.props.days)+" "+_vm._s(scope.props.dayTxt)+" •\n          "+_vm._s(scope.props.hours)+" "+_vm._s(scope.props.hourTxt)+" •\n          "+_vm._s(scope.props.minutes)+" "+_vm._s(scope.props.minutesTxt)+" •\n          "+_vm._s(scope.props.seconds)+" "+_vm._s(scope.props.secondsTxt))])]}},{key:"end-label",fn:function(scope){return [(
            scope.props.startLabel !== '' &&
            scope.props.tips &&
            scope.props.labelPosition === 'end'
          )?_c('span',[_vm._v(_vm._s(scope.props.startLabel)+":")]):_vm._e(),(
            scope.props.endLabel !== '' &&
            !scope.props.tips &&
            scope.props.labelPosition === 'end'
          )?_c('span',[_vm._v(_vm._s(scope.props.endLabel)+":")]):_vm._e()]}},{key:"end-text",fn:function(scope){return [_c('span',{staticClass:"ended-event"},[_vm._v(_vm._s(scope.props.endText))])]}}])})],1),(_vm.isEndedEvent())?_c('div',{attrs:{"id":"right-event-buttons"}},[(!_vm.isBooked && _vm.showBookButton)?_c('button',{staticClass:"bookThisEvent bookArea",on:{"click":_vm.goToEventBooking}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Book this event\n    ")]):(_vm.isUnAvailable())?_c('button',{staticClass:"mpButtonNotActive bookArea",attrs:{"disabled":""}},[_c('i',{staticClass:"fa fa-calendar"}),_vm._v(" Online Meeting Planner is not available\n    ")]):(_vm.isMpActive() && _vm.isBooked)?_c('router-link',{staticClass:"bookArea",attrs:{"to":{
        name: 'meetingPlanner',
        params: { eventUrl: _vm.event.url, eventId: _vm.event.id },
      }}},[_c('button',{staticClass:"bookThisEvent"},[_c('i',{staticClass:"fa fa-calendar"}),_vm._v(" Online Meeting Planner available\n      ")])]):(_vm.isBooked && !_vm.isMpActive())?_c('button',{staticClass:"mpButtonNotActive bookArea",attrs:{"disabled":""}},[_c('i',{staticClass:"fa fa-calendar"}),_vm._v("\n      Meeting Planner will be open at "+_vm._s(_vm.mpSubtractDays())+"\n    ")]):_vm._e(),(!_vm.isMobileScreen)?_c('br'):_vm._e(),_c('router-link',{staticClass:"becomeASponsor",attrs:{"to":{
        name: 'BecomeSponsorEvent',
        params: { eventId: _vm.$route.params.eventId },
      }}},[_c('button',{staticClass:"becomeASponsor-btn"},[_vm._v("Become a Sponsor")])]),(!_vm.isMobileScreen)?_c('br'):_vm._e(),_c('router-link',{staticClass:"panelistArea",attrs:{"to":{
        name: 'BecomePannelist',
        params: { eventId: _vm.$route.params.eventId },
      }}},[_c('button',{staticClass:"panelist"},[_vm._v("Become a Panelist")])])],1):_vm._e(),_c('div',{staticClass:"events-buttons"},[_c('b-row',{attrs:{"id":"all-event-btns"}},_vm._l((_vm.components),function(name){return _c('router-link',{key:name,staticClass:"standart-event-button-name",attrs:{"to":{ name: name, params: { event: _vm.event } }}},[_vm._v(_vm._s(name))])}),1),_c('keep-alive',[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }